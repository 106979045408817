<script>
    export let size = 24;
    export let title = "monday";
</script>

<svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path d="M6.77857 6.13902C7.7289 4.78181 9.59953 4.45197 10.9568 5.4023C12.314 6.35263 12.6438 8.22327 11.6935 9.58048L5.95771 17.772C5.00738 19.1292 3.13674 19.4591 1.77953 18.5087C0.422312 17.5584 0.0924684 15.6878 1.0428 14.3305L6.77857 6.13902Z" fill="#f62b54"/>
    <path d="M15.2678 6.22799C16.2182 4.87077 18.0888 4.54093 19.446 5.49126C20.8032 6.44159 21.1331 8.31223 20.1827 9.66945L14.447 17.861C13.4966 19.2182 11.626 19.548 10.2688 18.5977C8.91158 17.6474 8.58173 15.7767 9.53207 14.4195L15.2678 6.22799Z" fill="#ffcc00"/>
    <path d="M20.5 18.8595C22.1569 18.8595 23.5 17.5164 23.5 15.8595C23.5 14.2026 22.1569 12.8595 20.5 12.8595C18.8431 12.8595 17.5 14.2026 17.5 15.8595C17.5 17.5164 18.8431 18.8595 20.5 18.8595Z" fill="#00ca72"/>
</svg>